import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

let firebaseConfig;

if (process.env.GATSBY_ACTIVE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyAQzxJbztwDi73xHbTnCFrIz1heZELg3R8',
    authDomain: 'tas-courier-app.firebaseapp.com',
    databaseURL: 'https://tas-courier-app.firebaseio.com',
    projectId: 'tas-courier-app',
    storageBucket: 'tas-courier-app.appspot.com',
    messagingSenderId: '1091489699374',
    appId: '1:1091489699374:web:bcde0ce82230c7333eb1bf',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyBu1TvxkiKLg3An7V8j37_rxtHOYxqGIEs',
    authDomain: 'tas-courier-app-staging.firebaseapp.com',
    databaseURL: 'https://tas-courier-app-staging.firebaseio.com',
    projectId: 'tas-courier-app-staging',
    storageBucket: 'tas-courier-app-staging.appspot.com',
    messagingSenderId: '672182593411',
    appId: '1:672182593411:web:b1fef2b3575d7f66c4b8b6',
  };
}

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const fireAuth = firebase.auth();

export default firebase;
