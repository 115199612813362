import React, { createContext, ReactNode, useReducer, useContext } from 'react';
import { User } from 'firebase';

export type UserState = User | null;
type AuthState = {
  processed: boolean;
  loggedIn: boolean;
  user: UserState;
  role: string;
};

type LogoutAction = { type: 'logout' };
type UpdateUserAction = { type: 'updateUser'; payload: { user: UserState } };
type SetRoleAction = { type: 'setRole'; payload: { role: string } };
type ProcessAction = { type: 'process' };
type Action = LogoutAction | UpdateUserAction | SetRoleAction | ProcessAction;
type Dispatch = (action: Action) => void;
type AuthProviderProps = { children: ReactNode };

const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialAuthState = {
  processed: false,
  loggedIn: false,
  user: null,
  role: '',
};

function authReducer(state: AuthState, action: Action) {
  switch (action.type) {
    case 'logout': {
      return {
        ...state,
        loggedIn: false,
        user: null,
      };
    }
    case 'updateUser': {
      if (!action.payload) {
        throw new Error(`Expected payload`);
      }

      return {
        ...state,
        user: action.payload.user,
        loggedIn: action.payload.user !== null,
        role: action.payload.user === null ? '' : state.role,
      };
    }
    case 'setRole': {
      if (!action.payload) {
        throw new Error(`Expected payload`);
      }
      return {
        ...state,
        role: action.payload.role,
      };
    }
    case 'process': {
      return {
        ...state,
        processed: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const [authState, dispatch] = useReducer(authReducer, initialAuthState);

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
}
function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuthState, useAuthDispatch };
