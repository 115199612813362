import React, { FunctionComponent, useEffect } from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import { useAuthDispatch, useAuthState } from '../state/AuthContext';
import { fireAuth } from '../firebaseConf';
import usePageAuthenticator from '../hooks/usePageAuthenticator';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const Authenticated: FunctionComponent = ({ children }) => {
  const authDispatch = useAuthDispatch();
  const authState = useAuthState();
  const siteMetadata = useSiteMetadata();

  useEffect(() => {
    const unsubscribe = fireAuth.onAuthStateChanged(user => {
      authDispatch({ type: 'updateUser', payload: { user } });
      if (user !== null) {
        user.getIdTokenResult().then(({ claims }) => {
          authDispatch({ type: 'setRole', payload: { role: claims.role } });
          const authenticated = usePageAuthenticator(user, siteMetadata, claims.role);

          if (!authenticated) {
            navigate('/');
          }
          authDispatch({ type: 'process' });
        });
      } else {
        navigate('/auth/');
      }
    });
    return unsubscribe;
  }, []);

  return (
    <>
      <div className={classNames({ 'page-loader': false, hidden: authState.processed })} />
      {children}
    </>
  );
};

export default Authenticated;
