import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { globalHistory } from '@reach/router';
import LogoLight from './LogoLight';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { useAuthState } from '../state/AuthContext';
import { fireAuth } from '../firebaseConf';

const Navigation: FunctionComponent = () => {
  const navbarNode = useRef<HTMLInputElement>(null);
  const [logoutBusy, setLogoutBusy] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { menuItems } = useSiteMetadata();
  const { role, user } = useAuthState();
  const curPath = globalHistory.location.pathname;

  const toggleMobileMenuOpen = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const mobileMenuClickAway = (event: { target: HTMLInputElement }) => {
    if (!(navbarNode && navbarNode.current)) {
      return;
    }

    if (navbarNode.current.contains(event.target)) {
      return;
    }

    setMobileMenuOpen(false);
  };

  const onLogoutClick = () => {
    setLogoutBusy(true);
    fireAuth
      .signOut()
      .then(() => {
        setLogoutBusy(false);
      })
      .catch((error: Error) => {
        setLogoutBusy(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      // @ts-ignore
      document.addEventListener('mousedown', mobileMenuClickAway);
    } else {
      // @ts-ignore
      document.removeEventListener('mousedown', mobileMenuClickAway);
    }

    return () => {
      // @ts-ignore
      document.removeEventListener('mousedown', mobileMenuClickAway);
    };
  }, [mobileMenuOpen]);

  return (
    <nav ref={navbarNode} className="navbar is-primary has-shadow">
      <div className="navbar-brand">
        <div className="navbar-item">
          <LogoLight />
        </div>
        <span
          role="button"
          tabIndex={0}
          className="navbar-burger burger"
          data-target="navMenu"
          onClick={toggleMobileMenuOpen}
          onKeyDown={toggleMobileMenuOpen}
        >
          <span />
          <span />
          <span />
        </span>
      </div>
      <div className={classNames({ 'navbar-menu': true, 'is-active': mobileMenuOpen })}>
        <div className="navbar-start">
          {menuItems
            .filter(menuItem => menuItem.inMenu && menuItem.access.includes(role))
            .map(menuItem => {
              return (
                <Link
                  key={menuItem.link}
                  to={menuItem.link}
                  className={classNames({
                    'navbar-item': true,
                    'is-active': curPath === menuItem.link,
                  })}
                >
                  {menuItem.name}
                </Link>
              );
            })}
        </div>
        {user ? (
          <div className="navbar-end">
            <div className="navbar-item">
              <button
                type="button"
                className={classNames({
                  'button is-danger': true,
                  'is-loading': logoutBusy,
                })}
                onClick={onLogoutClick}
              >
                {user.displayName} Afmelden
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </nav>
  );
};

export default Navigation;
