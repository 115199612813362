import React, { FunctionComponent } from 'react';
import { AuthProvider } from '../state/AuthContext';
import Authenticated from '../components/Authenticated';
import Navigation from '../components/Navigation';
import useBackgroundImage from '../hooks/useBackgroundImage';

const AdminLayout: FunctionComponent = ({ children }) => {
  const bgImage = useBackgroundImage();

  return (
    <div className="app-container" style={{ backgroundImage: `url(${bgImage})` }}>
      <React.StrictMode>
        <AuthProvider>
          <Authenticated>
            <Navigation />
            <section className="section">
              <div className="container">{children}</div>
            </section>
          </Authenticated>
        </AuthProvider>
      </React.StrictMode>
    </div>
  );
};

export default AdminLayout;
