import { User } from 'firebase';
import { globalHistory } from '@reach/router';
import { UseSiteMetadata } from './useSiteMetadata';

const usePageAuthenticator = (user: User | null, metaData: UseSiteMetadata, role: string): boolean => {
  const { menuItems } = metaData;
  const curPath = globalHistory.location.pathname;

  const currentMenuItem = menuItems.find(menuItem => {
    return menuItem.link === curPath;
  });

  if (!currentMenuItem) {
    return false;
  }

  if (user === null) {
    return currentMenuItem.access.includes('anon');
  }

  return currentMenuItem.access.includes(role);
};

export default usePageAuthenticator;
