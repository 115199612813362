import { graphql, useStaticQuery } from 'gatsby';

const useBackgroundImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        fileName: file(base: { eq: "bg-pattern.png" }) {
          childImageSharp {
            fixed(width: 100) {
              src
            }
          }
        }
      }
    `
  );

  return data.fileName.childImageSharp.fixed.src;
};

export default useBackgroundImage;
