import React, { FunctionComponent } from 'react';
// @ts-ignore
import LogoLightSvg from '../images/logo-light.svg';

const LogoLight: FunctionComponent = () => {
  return (
    <figure className="svg-logo">
      <LogoLightSvg />
    </figure>
  );
};

export default LogoLight;
